import React, { useState, useEffect } from "react";
import "./index.scss";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import SucessModal from "../../modal/SucessModal";

const SiteMapSettings = () => {
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [success, setSuccess] = useState({ show: false, modalName: '' });
  const [editSuccess, setEditSuccess] = useState(false);
  const [fileName, setFileName] = useState({ siteMap: '', robots: '' });
  const [previewUrl, setPreviewUrl] = useState({
    siteMapUrl: null,
    robotsUrl: null,
    showSiteMapUrl: false,
    showRobotsUrl: false,
  });

  const [id, setId] = useState("");
  const [count, setCount] = useState(0);

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_URL}site-map-settings/list`)
      .then((res) => {
        setId(res.data.rows[0]?._id);
        setCount(res.data.count);
        setFileName({ siteMap: res?.data?.rows[0]?.siteMap, robots: res?.data?.rows[0]?.robots })
        setValue("facebook_pixel_code", res?.data?.rows[0]?.facebook_pixel_code);
        setValue("others", res?.data?.rows[0]?.others);
      });
  }, [count]);

  const handleInputChange = (event, fileNames) => {
    const file = event.target.files[0];
    if (file) {
      if (file && fileNames === 'siteMap') {
        // setPreviewUrl((val) => ({ ...val, siteMapUrl: file }))
        setFileName((val) => ({ ...val, siteMap: file.name }));
      } else if (file && fileNames === 'robots') {
        // setPreviewUrl((val) => ({ ...val, robotsUrl: file }))
        setFileName((val) => ({ ...val, robots: file.name }));
      }
      else {
        setFileName({ siteMap: '', robots: '' });
      }
    }
    // const reader = new FileReader();
    // reader.onload = () => {
    //   console.log('reader.result',reader.result);
    //   setPreviewUrl(reader.result);
    // };
    // reader.readAsDataURL(event.target.files[0]);
  };

  const onSubmit = (fields) => {

    const formData = new FormData();
    formData.append('siteMap', fields.siteMap[0]);
    formData.append('robots', fields.robots[0]);
    formData.set('facebook_pixel_code', fields.facebook_pixel_code);
    formData.set('others', fields.others);

    if (count === 0) {

      axios
        .post(`${process.env.REACT_APP_API_URL}site-map-settings/create`, formData)
        .then((response) => {
          if (response.data?._id) {
            setSuccess({ show: true, modalName: 'create' });
          } else {
            errors.submit_fail = response.data;
          }
        })
        .catch((err) => {
          errors.submit_fail = err.response.data.error;
        });
    }
    else {

      axios
        .put(
          `${process.env.REACT_APP_API_URL}site-map-settings/update/${id}`,
          formData
        )
        .then((response) => {
          if (response.data?._id) {
            setSuccess({ show: true, modalName: 'edit' });
          } else {
            alert("Fail");
          }
        });
    }
  };

  return (
    <>
      <div className="healtharticle">
        <div className="healtharticle-title ms-4">
          <Link
            to="/settings-social-media-link"
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
            }}
          >
            <span style={{ paddingLeft: "5%" }}>Settings</span>
          </Link>
          <span style={{ fontSize: "15px", paddingLeft: "1%" }}>&#62;</span>
          <Link
            to=""
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
              paddingLeft: "0.2%",
              width: "20%",
            }}
          >
            <span style={{ paddingLeft: "6%" }}>Site Map Settings</span>
          </Link>
        </div>
        <form className="carr-dep-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="">
            <div className="d-flex ms-2 mt-3">
              <div className="md-6">
                <label
                  className="car-dep-form-txt mt-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Site map file
                </label>
                <div className="meta-tag-file mt-2 d-flex align-items-center">
                  <input type="file"
                    className="hidden custom-file-input bg-white w-50"
                    accept=".xml,application/xml"
                    {...register("siteMap", { defaultValue: "fileName" })}
                    onChange={(e) => handleInputChange(e, "siteMap")}
                  />
                  <label>{fileName.siteMap === '' ? "No file Choosen" : fileName.siteMap}</label>
                </div>
                {/* <div className="mt-2 text-center"><button type='button' className="preview-file-input" onClick={() => previewFile('siteMap')}>Preview File</button></div> */}

                {/* {previewUrl.showSiteMapUrl || previewUrl.showRobotsUrl && (
                  <div>
                    <embed src={previewUrl.siteMapUrl} width="500" height="300" />
                  </div>
                )} */}

              </div>
              <div className="md-6 ms-5">
                <label
                  className="car-dep-form-txt mt-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Robots File Upload
                </label>
                <div className="meta-tag-file mt-2 d-flex align-items-center">
                  <input type="file"
                    className="hidden custom-file-input bg-white w-50"
                    accept=".txt,text/plain"
                    {...register("robots", { defaultValue: "fileName" })}
                    onChange={(e) => handleInputChange(e, "robots")}
                  />
                  <label>{fileName.robots === '' ? "No file Choosen" : fileName.robots}</label>
                </div>
                {/* <div className="mt-2 text-center"><button type='button' className="preview-file-input" onClick={() => previewFile('robots')}>Preview File</button></div> */}

              </div>
            </div>

            {/*--------------Site Map Settings----------*/}
            <div className="mt-3 ms-2">
              <label
                className="car-dep-form-txt mt-2"
                style={{ color: "#4B4853" }}
                htmlFor=""
              >
                Analytics Code
              </label>
              <div className=" mt-2">
                <textarea
                  className="site-map-text-area"
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </div>

            {/*--------------Site Map Settings----------*/}
            <div className="mt-3 ms-2">
              <label
                className="car-dep-form-txt mt-2"
                style={{ color: "#4B4853" }}
                htmlFor=""
              >
                Google Ads
              </label>
              <div className="md-12 mt-2">
                <textarea
                  className="site-map-text-area"
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </div>
            {/*--------------Facebook Pixel Code----------*/}
            <div className="mt-3 ms-2">
              <label
                className="car-dep-form-txt mt-2"
                style={{ color: "#4B4853" }}
                htmlFor=""
              >
                Facebook Pixel Code
              </label>
              <div className="md-12 mt-2">
                <textarea
                  className="site-map-text-area"
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  {...register("facebook_pixel_code")}
                ></textarea>
              </div>
            </div>
            {/*--------------Others----------*/}
            <div className="mt-3 ms-2">
              <label
                className="car-dep-form-txt mt-2"
                style={{ color: "#4B4853" }}
                htmlFor=""
              >
                Others
              </label>
              <div className="md-12 mt-2">
                <textarea
                  className="site-map-text-area"
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  {...register("others")}
                ></textarea>
              </div>
            </div>
            <div className="car-dep-form-button mb-5 mt-5">
              <button type="submit">SUBMIT</button>
            </div>
          </div>
        </form>
      </div>
      {success.show ? <SucessModal
        name='Site map'
        post={success.modalName === 'create' ? 'Created' : "Updated"}
      /> : null}
    </>
  );
};

export default SiteMapSettings;
