import Moment from "moment";
import { useEffect, useState } from "react";
import { AiOutlineBackward, AiOutlineForward } from "react-icons/ai";
import { useNavigate, useParams } from "react-router";
import { LegalPageAppDownloadLinks } from "../../constants";
import { SortByModel } from "../Common/filtersRootModals";

import ReactPaginate from "react-paginate";
import apiCall from "../../api";
import newdelete from "../../assets/images/HealthSy/delete.png";
import onboard from "../../assets/images/HealthSy/onboard.png";
import search from "../../assets/images/searchIcon.png";
import DoctorsFilterOptions from "../Common/doctorsFilterOptions";
import Deletepopup from "../modal/Deletepopup";
import DownloadLinkBanner from "./DownloadLinkBanner";

const DownloadLink = () => {

  const { id } = useParams();
  const navigate = useNavigate();

  const [skip, setSkip] = useState(0);
  const [pages, setPages] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState("newest");
  const [records, setRecords] = useState([]);
  const [textColor, setTextColor] = useState([]);
  const [pageOffset, setPageOffset] = useState(0);
  const [sortshow, setSortShow] = useState(false);
  const [sortName, setSortName] = useState("Sort by");
  const [selectedSort, setSelectedSort] = useState([]);
  const [deleteModal, setDeleteModal] = useState({ id: "", status: false });

  useEffect(() => {
    list_api_call();
  }, [id]);

  const handleSortClick = (sortOption, colorCode) => {
    setSelectedSort(sortOption);
    setTextColor(colorCode);
  };

  const handleApplyClick = () => {
    if (selectedSort) {
      setSort(selectedSort);
      setSortShow(false);
    }
  };

  const handleClose = () => {
    setDeleteModal({ id: "", status: false });
  };

  const handleDelete = async (id) => {
    try {
      const response = await apiCall(`store-link/delete/${id}`, "DELETE");

      if (response) {
        list_api_call();
        setDeleteModal({ id: "", status: false });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleActive = async (id, status) => {
    try {
      await apiCall(`store-link/update/active/${id}`, "PATCH", {
        active: !status,
      });
      list_api_call();
    } catch (err) {
      console.error(err);
    }
  };

  const list_api_call = async () => {

    const platform = localStorage.getItem('selected_platform')
    if (!platform) {
      setRecords([])
      return;
    }

    const response = await apiCall(`store-link/list`, "POST",
      { id: platform },
    )
    setRecords(response?.data?.rows);
  };

  return (
    <>
      <div className="mt-5 appDownloadLinkContainer p-4">
        <DownloadLinkBanner
          dataValues={LegalPageAppDownloadLinks}
        />
        {/* <DoctorsFilterOptions
          search={search}
          sortshow={sortshow}
          sortName={sortName}
          setSortShow={setSortShow}
        /> */}
        <div className="mt-4">
          {records?.length ? (
            <div className=" appDownloadLinkTable ms-4">
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <td className="">S.No</td>
                    <td className="" style={{ width: "7%" }}>
                      Web Page Name
                    </td>
                    <td className="">Date / Time of Last Update</td>
                    <td className="">Page Type</td>
                    <td className="">Meta Title</td>
                    <td className="">Meta Description</td>
                    <td className="">Meta Keyword</td>
                    {/* <td className="">Status</td> */}
                    <td className="">Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {records.map((el, i) => {
                    return (
                      <tr key={el._id}>
                        <td>{i + 1}</td>
                        <td>{el.webpage_name}</td>
                        <td>{Moment(el.createdAt).format("DD MMMM YYYY LT")}</td>
                        <td>{el.page_type?.page_name}</td>
                        <td>{el.meta_title}</td>
                        <td>{el.meta_description}</td>
                        <td>{el.meta_keyword}</td>
                        {/* <td>{el.active === true ? "Verified" : "Not Verified"}</td> */}
                        <td>
                          <span
                            className="newdelete text-success px-0"
                            onClick={() => {
                              navigate(`/app-download-link/${el._id}/edit-download-link`);
                            }}
                          >
                            Edit
                          </span>
                          <span className="newdelete-logo">
                            <img src={onboard} alt="" />
                          </span>
                          <span
                            className="inactive-health newdelete"
                            onClick={() => {
                              handleActive(el._id, el.active);
                            }}
                          >
                            {el.active ? "Inactive" : "Active"}
                          </span>
                          <span className="newdelete-logo p-3">
                            <img src={newdelete} alt="" />
                          </span>
                          <span
                            className="newdelete p-0"
                            onClick={() => {
                              setDeleteModal({ id: el._id, status: true });
                            }}
                          >
                            Delete
                          </span>
                          <span className="newdelete-logo">
                            <img src={newdelete} alt="" />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="mt-4"> No Records </div>
          )}
        </div>
      </div>
      {/* <div>
        <div className="mb-5 col d-flex pagination justify-content-end admin-pagination">
          <button
            className="page-link"
            disabled={skip === 0}
          >
            <AiOutlineBackward size={20} />
          </button>
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            containerClassName="pagination my-0"
            activeClassName="active"
            forcePage={pageOffset}
          />
          <button
            className="page-link"
            disabled={(skip / limit) === (pages - 1)}
          >
            <AiOutlineForward size={20} />
          </button>
        </div>
      </div> */}
      {sortshow ? (
        <SortByModel
          latest="Latest"
          oldest="Oldest"
          handleSortClick={handleSortClick}
          selectedSort={selectedSort}
          textColor={textColor}
          handleApplyClick={handleApplyClick}
        />
      ) : null}
      {deleteModal.status === true && (
        <Deletepopup
          handleDelete={handleDelete}
          id={deleteModal?.id}
          isCloseModalEnable={true}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default DownloadLink;