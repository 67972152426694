import axios from "axios";
import apiCall from "../../../api";
import ReactPaginate from "react-paginate";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { AiOutlineForward, AiOutlineBackward } from "react-icons/ai";
import { experience, filterOptions, gender, language, service } from "../../../constants";

import search from "../../../assets/images/searchIcon.png";
import filter1 from "../../../assets/svg/Health Article/filtericon.svg";
import sort1 from "../../../assets/svg/Health Article/sortbyicon.svg";

// actions icons
import Autocomplete from "react-google-autocomplete";
import contact from "../../../assets/images/HealthSy/contact.png";
import newdelete from "../../../assets/images/HealthSy/delete.png";
import FilterModal from "../../Common/filterModal";
import FilterRootModal from "../../Common/filterRootModal";
import AssignedTo from "../../modal/AssignedTo";
import Deletepopup from "../../modal/Deletepopup";
import LeadStatus from "../../modal/LeadStatus";
import LeadStatusConfirm from "../../modal/LeadStatusConfirm";
import SucessModal from "../../modal/SucessModal";

const DoctorRegistration = () => {

  const [records, setRecords] = useState([]);
  const navigate = useNavigate();
  const [filtered_records, setFilteredRecords] = useState([]);
  const [pages, setPages] = useState(0);

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState("newest");
  const [sortName, setSortName] = useState("Sort by");

  //filter portion
  const [filtershow, setFilterShow] = useState(false);
  const [sortshow, setSortShow] = useState(false);
  const [textcolor, setTextColor] = useState(0);

  const [expdata, setExpData] = useState([]);
  const [genderdata, setGenderData] = useState([]);
  const [languagedata, setLanguageData] = useState([]);
  const [servicedata, setServiceData] = useState([]);
  const [location, setLocation] = useState("");

  const [assignModal, setAssignModal] = useState({ id: "", status: false })
  const [leadStatusModal, setLeadStatusModal] = useState({ id: "", status: false })
  const [success, setSuccess] = useState(false);
  const [leadStatussuccess, setLeadStatusSuccess] = useState(false);
  const [leadStatusConfirmModal, setLeadStatusConfirmModal] = useState({ leadType: "", status: false, id: "" })
  const [leadStatus, setLeadStatus] = useState([]);
  const [filterLeadStatus, setFilterLeadStatus] = useState([]);
  const [bdModal, setBdModal] = useState([]);
  const [filterBdModal, setFilterBdModal] = useState([]);
  const [pageOffset, setPageOffset] = useState(0);

  useEffect(() => {
    getLeadStatus()
    getBdModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    list_api_call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, sort, skip]);

  const getLeadStatus = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}lead-status/list`)
      .then((res) => {
        setLeadStatus(res.data.rows.map((lead) => lead?.type));
      });
  };

  const getBdModal = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}bd/list`)
      .then((res) => {
        setBdModal(res.data.rows.map((data) => data?.bd_name));
      });
  };

  const handleDoctor = () => {
    navigate("/doctor-registration");
  };
  const handleService = () => {
    navigate("/healthcare-registration");
  };
  const handleRetail = () => {
    navigate("/pharmacy-registration");
  };
  const handleOthers = () => {
    navigate("/other-registration");
  };
  const handleBookDemo = () => {
    navigate("/doctor-demo");
  };
  const handleInstaDoc = () => {
    navigate("/insta-doc");
  };

  const filter_call = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}healthcare-registration/list`, {
        // key: "healthcare_service",
        values: {
          service_category: servicedata,
          experience: expdata,
          gender: genderdata,
          languages_known: languagedata,
          location: location,
          lead_status: filterLeadStatus,
          assigned_to: filterBdModal
        },
        skip: skip,
        limit: limit,
        sort: sort,
      })
      .then((res) => {
        setRecords(res.data?.rows);
        setPages(Math.ceil(res.data.count / limit));
      });
  };

  const list_api_call = async () => {
    const result = await apiCall(`healthcare-registration/list`, "POST",
      {
        // ...filter,
        skip: skip,
        limit: limit,
        // key: "healthcare_service",
        sort: sort,
      })
    setFilteredRecords([]);
    setRecords(result.data?.rows);
    // setRowCount(result.data.count);
    setPages(Math.ceil(result.data.count / limit));
  };

  const filter_records = (filter) => {
    let rows = records.filter((x) => {
      // return JSON.stringify(x).indexOf(filter) > -1;
      return JSON.stringify(x).toLowerCase().indexOf(filter.toLowerCase()) > -1;
    });
    setFilteredRecords(rows);
  };

  //Actions

  //Delete Functionality
  const [del, setDel] = useState(false);
  const [id, setId] = useState("");

  const handleDelete = (id) => {
    fetch(
      `${process.env.REACT_APP_API_URL}healthcare-registration/delete/${id}`,
      {
        method: "DELETE",
      }
    ).then((result) => {
      result.json().then((res) => {
        list_api_call();
        setDel(false);
      });
    });
  };

  const successModalClose = () => {
    setSuccess(false)
    setLeadStatusSuccess(false)
    list_api_call();
  }

  const handlePageChange = (event) => {
    setSkip(event.selected * limit)
    setPageOffset(event.selected);
  };

  return (
    <>
      {/* <Home> */}
      <div className="healtharticle">
        <div className="healtharticle-container">
          <div className="top-container ps-3 ">
            <div className="row ">
              <div className="healtharticle-title  ms-0">
                <span>
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    to="/dashboard"
                  >
                    Dashboard
                  </Link>
                </span>
                <span>&#62;</span>
                <span>
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    to="/doctor-registration"
                  >
                    HealthSynergy
                  </Link>
                </span>
                <span style={{ paddingLeft: "0.4%" }}>&#62;</span>
                <span style={{ paddingLeft: "0.4%" }}>
                  HealthSy Partnered Home Healthcare Service Provider Network
                  Programme
                </span>
              </div>
            </div>

            <div className="careers-tab  ">
              <div className="career-tab-career " onClick={handleDoctor}>
                Doctors
              </div>
              <div className="career-tab-jobpost " onClick={handleRetail}>
                Retail Pharmacy
              </div>
              <div className="career-post-department pink-line">
                Home Healthcare Service Providers
              </div>
              <div className="career-post-department" onClick={handleInstaDoc}>
                InstaDoc
              </div>
              <div
                className="career-post-position  other"
                onClick={handleOthers}
              >
                Others
              </div>
              <div
                className="career-post-position "
                style={{ color: "#65626C" }}
                onClick={handleBookDemo}
              >
                Book a Demo
              </div>
            </div>
            <div className="healtharticle-show mt-5 ms-2">
              <div>Show</div>
              <div>
                <select
                  value={limit}
                  onChange={(e) => setLimit(e.target.value)}
                  className="me-2"
                >
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                  <option>100</option>
                  <option value="0">All</option>
                </select>
              </div>
              <div>entries</div>
              <div>
                <div>
                  {/* <FaSearch /> */}
                  <img src={search} alt="" />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    onChange={(e) => filter_records(e.target.value)}
                  />
                </div>
              </div>
              <div className="sortandfilter-new">
                <div className="sortByPosition">
                  <div>
                    <div>
                      <div
                        onClick={() => {
                          setSortShow(!sortshow);
                        }}
                      >
                        {sortName}
                      </div>
                      {/* <select onChange={(e) => setSort(e.target.value)}>
                          <option>Sort by</option>
                          <option value="id-desc"><div style={{color:"red"}}>Oldest</div></option>
                          <option value="name-asc"><div>Newest</div></option>
                          <option value="id-asc"><div>Alphabetical ↑</div></option>
                          <option value="id-asc"><div>Alphabetical ↓</div><span><img src="" alt="" /></span></option>
                        </select> */}
                    </div>
                    <div>
                      <img src={sort1} alt="" />
                    </div>
                  </div>
                </div>
                <div className="sortByDepartment">
                  <div>
                    <div>
                      <div
                        onClick={() => {
                          setFilterShow(true);
                        }}
                      >
                        Filter
                      </div>
                      {/* <select
                        name=""
                        id=""
                        // onChange={handleSort}
                      >
                        <option value="">Filter</option>
                        <option value="Asc to Des">Asc to Des</option>
                        <option value="Des to Asc">Des to Asc</option>
                      </select> */}
                    </div>
                    <div>
                      <img src={filter1} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="healtharticle-table healthcare-table">
            <table className="">
              <thead className="">
                <tr>
                  <td className="align-middle ">S No</td>
                  <td className="sno ">Time of Registration</td>
                  <td className="sno ">Date of Registration</td>
                  <td className="align-middle">Name of the HHSP</td>
                  <td className="align-middle">Mobile Number</td>
                  <td className="align-middle">Email ID</td>
                  <td className="align-middle">Degree</td>
                  <td className="align-middle">Other Certified Courses</td>
                  <td className="align-middle">Age</td>
                  <td className="align-middle">Gender</td>
                  <td className="align-middle">Service Category</td>
                  <td className="align-middle">Experience (Year of Start)</td>
                  <td className="align-middle">License Number / Council Number /  Registration Number</td>
                  <td className="align-middle">Memberships / Associations Details</td>
                  <td className="align-middle">About Yourself</td>
                  <td className="align-middle">Fee</td>
                  <td className="align-middle">Are your currently available on other home healthcare platforms?</td>
                  <td className="align-middle">Your Current Organisation Name (Hospital / Clinic Name)</td>
                  <td className="align-middle">Language</td>
                  <td className="align-middle">State</td>
                  <td className="align-middle">City</td>
                  <td className="align-middle">Location (City / Town)</td>
                  <td className="align-middle">Address</td>
                  <td className="align-middle">Pincode</td>
                  <td className="align-middle">BD Name</td>
                  <td>App Download Android Click</td>
                  <td>App Download iOS Click</td>
                  <td>Brochure Icon Click Status (1.0)</td>
                  <td className="">Assigned To</td>
                  <td className="">Lead Status</td>
                  <td className="align-middle">Actions</td>
                </tr>
              </thead>
              <tbody>
                {(filtered_records.length > 0 ? filtered_records : records).map(
                  (x, i) => {
                    if (x.show === undefined || x.show === true)
                      return (
                        <tr key={x._id}>
                          <td>{skip + (i + 1)}</td>
                          <td>{Moment(x.createdAt).format("LT")}</td>
                          <td>{Moment(x.createdAt).format("DD-MM-YYYY")}</td>
                          <td>{x.name}</td>
                          <td>{x.mobile}</td>
                          <td>{x.email}</td>
                          <td>{x.degree ? x.degree : x.qualification}</td>
                          <td>{x.other_certified_courses ? x.other_certified_courses : "-"}</td>
                          <td>{x.age}</td>
                          <td>{x.gender}</td>
                          <td>{x.service_category}</td>
                          <td>{x.experience}</td>
                          <td>{x.license_number}</td>
                          <td>{x.memberships_or_associations ? x.memberships_or_associations : "-"}</td>
                          <td>{x.about ? x.about : "-"}</td>
                          <td>{x.fee}</td>
                          <td>{x.other_home_healthcare_platforms}</td>
                          <td>{x.establishment}</td>
                          <td>{x?.languages_known?.join(", ")}</td>
                          <td>{x.state ? x.state : "-"}</td>
                          <td>{x.city ? x.city : "-"}</td>
                          <td>{x.location ? x.location : "-"}</td>
                          <td>{x.address ? x.address : "-"}</td>
                          <td>{x.pincode}</td>
                          <td>{"-"}</td>
                          <td>{x.android_click}</td>
                          <td>{x.ios_click}</td>
                          <td>{x.brochure_click}</td>
                          <td>{x.assigned_to ? x.assigned_to : "-"}</td>
                          <td>{x.lead_status ? x.lead_status : "-"}</td>
                          <td>
                            <span
                              className="pe-1 primaryColor ps-2"
                              onClick={() => setAssignModal({ id: x._id, status: true })}
                            >
                              Assigned
                            </span>
                            <span className="pe-1">
                              <FaAngleDown className="drop-down-arrow" />
                            </span>
                            <span
                              className="pe-1 primaryColor ps-2"
                              onClick={() => setLeadStatusModal({ id: x._id, status: true })}
                            >
                              Lead Status
                            </span>
                            <span className="pe-1">
                              <FaAngleDown className="drop-down-arrow" />
                            </span>
                            <span
                              className="text-primary cursorPointer ms-2"
                              onClick={() => { navigate(`/healthcare-registration-edit/${x._id}`) }}
                            >
                              Edit
                            </span>
                            <span className="contact-logo">
                              <img src={contact} alt="" />
                            </span>
                            <span
                              className="newdelete"
                              onClick={() => {
                                setId(x._id);
                                setDel(true);
                              }}
                            >
                              Delete
                            </span>
                            <span className="newdelete-logo">
                              <img src={newdelete} alt="" />
                            </span>
                          </td>
                        </tr>
                      );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div>
        <div className="mb-5 col d-flex pagination justify-content-end admin-pagination">
          <button
            type="button"
            className="page-item page-link"
            value="<<"
            onClick={() => handlePageChange({ selected: 0 })}
            disabled={skip === 0}
          >
            <AiOutlineBackward size={20} />
          </button>
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName="pagination my-0"
            activeClassName="active"
            forcePage={pageOffset}
          />
          <button
            type="button"
            value=">>"
            className="page-link"
            onClick={() => handlePageChange({ selected: pages - 1 })}
            disabled={(skip / limit) === (pages - 1)}
          >
            <AiOutlineForward size={20} />
          </button>
        </div>
      </div>
      {/* </Home> */}
      {del === true && <Deletepopup handleDelete={handleDelete} id={id} />}

      {/*---------Filter Portion--------*/}
      {filtershow ? (
        <FilterRootModal
          filterOptions={filterOptions}
          onClickClear={() => {
            setFilterShow(false);
            setExpData([]);
            setGenderData([]);
            setLanguageData([]);
            setLocation("");
            setFilterLeadStatus([]);
            setFilterBdModal([]);
            list_api_call()
          }}
          onClickApply={() => {
            setFilterShow(false);
            filter_call()
          }}
          selectOption={textcolor}
          setSelectOption={setTextColor}
        />
      ) : null}

      {/*-------Gender--------*/}
      {textcolor === 1 && filtershow === true ? (
        <FilterModal
          title={"Gender"}
          data={gender}
          className="filterModelNestedLevel"
          activeFilters={genderdata}
          onChange={(e) => {
            if (genderdata.includes(e.target.name)) {
              setGenderData(genderdata.filter((ele) => ele !== e.target.name))
            } else {
              setGenderData((pre) => [...pre, e.target.name])
            }
          }}
        />
      ) : null}

      {/*-------Experience--------*/}
      {textcolor === 2 && filtershow === true ? (
        <FilterModal
          title={"Experience"}
          data={experience}
          className="filterModelNestedLevel"
          activeFilters={expdata}
          onChange={(e) => {
            if (expdata.includes(e.target.name)) {
              setExpData(expdata.filter((ele) => ele !== e.target.name))
            } else {
              setExpData((pre) => [...pre, e.target.name])
            }
          }}
        />
      ) : null}

      {/*-------City / Town--------*/}
      {textcolor === 3 && filtershow === true ? (
        <div className="filter-doctor-fee">
          <div className="filter-doctor-fee-header">City / Town / Location</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            <div className="filter-doctor-search">
              <div>
                <div>
                  <img src={search} alt="" />
                </div>
                <div>
                  {/* <input 
                    type="text" 
                    name="" 
                    id="filter-search" 
                    placeholder="Search"
                    onChange={(e)=>setLocation(e.target.value)}
                  /> */}
                  <Autocomplete
                    apiKey={"AIzaSyBJUZFe3zt4HNVzwdTlmJYMWtXhZ7mfclk"}
                    onPlaceSelected={(place) => {
                      setLocation(place.formatted_address);
                    }}
                    types={["address"]}
                    className="form-control newlocation"
                    defaultValue={location}
                    placeholder=""
                    componentRestrictions={{ country: "us" }}
                    preventDefault
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/*-------Language--------*/}
      {textcolor === 4 && filtershow === true ? (
        <FilterModal
          title={"Language"}
          data={language}
          className="filterModelNestedLevel"
          activeFilters={languagedata}
          onChange={(e) => {
            if (languagedata.includes(e.target.name)) {
              setLanguageData(languagedata.filter((ele) => ele !== e.target.name))
            } else {
              setLanguageData((pre) => [...pre, e.target.name])
            }
          }}
        />
      ) : null}

      {/*-------Language--------*/}
      {textcolor === 5 && filtershow === true ? (
        <FilterModal
          title={"Service Category"}
          data={service}
          className="filterModelNestedLevel"
          activeFilters={expdata}
          onChange={(e) => {
            if (servicedata.includes(e.target.name)) {
              setServiceData(servicedata.filter((ele) => ele !== e.target.name))
            } else {
              setServiceData((pre) => [...pre, e.target.name])
            }
          }}
        />
      ) : null}
      {/*-------Lead Status--------*/}
      {textcolor === 6 && filtershow === true ? (
        <FilterModal
          title={"Lead Status"}
          data={leadStatus}
          className="filterModelNestedLevel"
          activeFilters={filterLeadStatus}
          onChange={(e) => {
            if (filterLeadStatus.includes(e.target.name)) {
              setFilterLeadStatus(filterLeadStatus.filter((ele) => ele !== e.target.name))
            } else {
              setFilterLeadStatus((pre) => [...pre, e.target.name])

            }
          }}
        />
      ) : null}

      {/* -------BD-------- */}
      {textcolor === 7 && filtershow === true ? (
        <FilterModal
          title={"BD"}
          data={bdModal}
          className="filterModelNestedLevel"
          activeFilters={filterBdModal}
          onChange={(e) => {
            if (filterBdModal.includes(e.target.name)) {
              setFilterBdModal(filterBdModal.filter((ele) => ele !== e.target.name))
            } else {
              setFilterBdModal((pre) => [...pre, e.target.name])
            }
          }}
        />
      ) : null}

      {/*--------Sort By Portion---------*/}

      {sortshow ? (
        <div className="sortby-Common">
          <div
            onClick={() => {
              setTextColor(11);
              setSortShow(false);
              setSort("oldest");
              setSortName("Oldest");
            }}
            style={{
              color: textcolor === 11 ? "#CB1B5B" : "",
              fontWeight: textcolor === 11 ? "bold" : "",
            }}
          >
            Oldest
          </div>
          <div
            onClick={() => {
              setTextColor(12);
              setSortShow(false);
              setSort("newest");
              setSortName("Newest");
            }}
            style={{
              color: textcolor === 12 ? "#CB1B5B" : "",
              fontWeight: textcolor === 12 ? "bold" : "",
            }}
          >
            Newest
          </div>
          <div
            onClick={() => {
              setTextColor(13);
              setSortShow(false);
              setSort("ascending");
              setSortName("Ascending");
            }}
            style={{
              color: textcolor === 13 ? "#CB1B5B" : "",
              fontWeight: textcolor === 13 ? "bold" : "",
            }}
          >
            Alphabetical ↑
          </div>
          <div
            onClick={() => {
              setTextColor(14);
              setSortShow(false);
              setSort("descending");
              setSortName("Descending");
            }}
            style={{
              color: textcolor === 14 ? "#CB1B5B" : "",
              fontWeight: textcolor === 14 ? "bold" : "",
            }}
          >
            Alphabetical ↓
          </div>
        </div>
      ) : null}

      {assignModal.status === true && (
        <AssignedTo
          id={assignModal?.id}
          setAssignModal={setAssignModal}
          setSuccess={setSuccess}
          pathName="healthcare-registration"
        />
      )}

      {success && (
        <SucessModal
          name="BD"
          post="Assigned"
          onClick={() => successModalClose()}
        />
      )}

      {leadStatusModal.status === true && (
        <LeadStatus
          id={leadStatusModal?.id}
          setLeadStatusModal={setLeadStatusModal}
          setLeadStatusSuccess={setLeadStatusSuccess}
          setLeadStatusConfirmModal={setLeadStatusConfirmModal}
        />
      )}
      {leadStatusConfirmModal.status === true && (
        <LeadStatusConfirm
          id={leadStatusConfirmModal?.leadType}
          setLeadStatusConfirmModal={setLeadStatusConfirmModal}
          setLeadStatusSuccess={setLeadStatusSuccess}
          leadStatusConfirmModal={leadStatusConfirmModal}
          formType="healthcare-registration"
        />
      )}
      {leadStatussuccess && (
        <SucessModal
          name="Lead Status"
          post="Updated"
          onClick={() => successModalClose()}
        />
      )}
    </>
  );
};

export default DoctorRegistration;