
export const CTACategoryList = [
    { name: "Retail Pharmacies", slugurl: "retail-pharmacies" },
    { name: "Doctors", slugurl: "doctors" },
    { name: "Home Healthcare Service Providers", slugurl: "home-healthcare-service-providers" }
]

export const AppDownloadLinks = [
    { name: "User App", slugurl: "user-app" },
    { name: "Doctor App", slugurl: "doctor-app" },
    { name: "Home Healthcare App", slugurl: "home-healthcare-app" },
    { name: "Pharmacy App", slugurl: "pharmacy-app" }
]

export const LegalPageAppDownloadLinks = [
    { name: "Website", slugurl: "website" },
    { name: "User App", slugurl: "user-app" },
    { name: "Doctor App", slugurl: "doctor-app" },
    { name: "Pharmacy App", slugurl: "pharmacy-app" },
    { name: "HHSP App", slugurl: "home-healthcare-app" },
    { name: "Delivery Partner App", slugurl: "delivery-partner-app" },
    { name: "InstaDoc", slugurl: "insta-doc" },
    { name: "Legal Notes", slugurl: "legal-notes" },
]

export const slugUrl = (slugName) => slugName?.trim()?.toLowerCase().replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-')

export const convertSlugToNormalString = (slug) => {
    if (!slug) {
        return '';
    }
    const stringWithoutHyphens = slug.replace(/-/g, ' ');
    const capitalizedString = stringWithoutHyphens.charAt(0).toUpperCase() + stringWithoutHyphens.slice(1);
    return capitalizedString;
};

export function convertToTitleCase(str) {
    // Split the string into an array of words
    var words = str.split('-');

    // Capitalize the first letter of each word
    var capitalizedWords = words.map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
    });

    // Join the words back into a single string
    var result = capitalizedWords.join(' ');

    return result;
}

export const adLandingFormsList = [
    { name: "Partnered Doctors", slugurl: "partnered-doctors" },
    { name: "Partnered Retail Pharmacies", slugurl: "partnered-retail-pharmacies" },
    { name: "Users General", slugurl: "users-general" },
    { name: "Users - Order Medicines", slugurl: "users-order-medicines" },
    { name: "Users - Online Doctor Consultations", slugurl: "users-online-doctor-consultations" },
]

export const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Mehalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajastan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Delhi(NCT)",
    "Jammu and Kashmir",
    "Ladakh",
    "Lakshwadeep",
    "Puducherry"
]

export const startedYear = [
    "2023",
    "2022",
    "2021",
    "2020",
    "2019",
    "2018",
    "2017",
    "2016",
    "2015",
    "2014",
    "2013",
    "2012",
    "2011",
    "2010",
    "2009",
    "2008",
    "2007",
    "2006",
    "2005",
    "2004",
    "2003",
    "2002",
    "2001",
    "2000",
    "1999",
    "1998",
    "1997",
    "1996",
    "1995",
    "1994",
    "1993",
    "1992",
    "1991",
    "1990",
    "1989",
    "1988",
    "1987",
    "1986",
    "1985",
    "1984",
    "1983",
    "1982",
    "1981",
    "1980",
    "1979",
    "1978",
    "1977",
    "1976",
    "1975",
    "1974",
    "1973",
    "1972",
    "1971",
    "1970",
    "1969",
    "1968",
    "1967",
    "1966",
    "1965",
    "1964",
    "1963",
    "1962",
    "1961",
    "1960",
]

export const medicalCouncilYears = [
    "2024",
    "2023",
    "2022",
    "2021",
    "2020",
    "2019",
    "2018",
    "2017",
    "2016",
    "2015",
    "2014",
    "2013",
    "2012",
    "2011",
    "2010",
    "2009",
    "2008",
    "2007",
    "2006",
    "2005",
    "2004",
    "2003",
    "2002",
    "2001",
    "2000",
    "1999",
    "1998",
    "1997",
    "1996",
    "1995",
    "1994",
    "1993",
    "1992",
    "1991",
    "1990",
    "1989",
    "1988",
    "1987",
    "1986",
    "1985",
    "1984",
    "1983",
    "1982",
    "1981",
    "1980",
    "1979",
    "1978",
    "1977",
    "1976",
    "1975",
    "1974",
    "1973",
    "1972",
    "1971",
    "1970",
    "1969",
    "1968",
    "1967",
    "1966",
    "1965",
    "1964",
    "1963",
    "1962",
    "1961",
    "1960",
]

export const degree = [
    "MBBS",
    "MS",
    "MD",
    "BDS",
    "MDS",
    "BAMS",
    "Others"
]

export const medicalCouncil = [
    "Andhra Pradesh Medical Council",
    "Arunachal Pradesh Medical Council",
    "Assam Medical Council",
    "Bhopal Medical Council",
    "Bihar Medical Council",
    "Bombay Medical Council",
    "Chandigarh Medical Council",
    "Chattisgarh Medical Council",
    "Delhi Medical Council",
    "Goa Medical Council",
    "Gujarat Medical Council",
    "Haryana Medical Councils",
    "Himachal Pradesh Medical Council",
    "Hyderabad Medical Council",
    "Jammu & Kashmir Medical Council",
    "Jharkhand Medical Council",
    "Karnataka Medical Council",
    "Madhya Pradesh Medical Council",
    "Madras Medical Council",
    "Mahakoshal Medical Council",
    "Maharashtra Medical Council",
    "Manipur Medical Council",
    "Medical Council of India",
    "Medical Council of Tanganyika",
    "Mizoram Medical Council",
    "Mysore Medical Council",
    "Nagaland Medical Council",
    "Orissa Council of Medical Registration",
    "Pondicherry Medical Council",
    "Punjab Medical Council",
    "Rajasthan Medical Council",
    "Sikkim Medical Council",
    "Tamil Nadu Medical Council",
    "Telangana State Medical Council",
    "Travancore Cochin Medical Council, Trivandrum",
    "Tripura State Medical Council",
    "Uttar Pradesh Medical Council",
    "Uttarakhand Medical Council",
    "Vidharba Medical Council",
    "West Bengal Medical Council",
    "Others"
]

export const config = {
    zIndex: 0,
    readonly: false,
    iframe: true,
    useInputsPlaceholder: true,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 474,
    activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about'],
    toolbarButtonSize: 'middle',
    theme: 'default',
    enableDragAndDropFileToEditor: true,
    saveModeInCookie: false,
    spellcheck: true,
    editorCssClass: true,
    triggerChangeEvent: true,
    height: 220,
    direction: 'ltr',
    language: 'en',
    debugLanguage: false,
    i18n: 'en',
    tabIndex: -1,
    toolbar: true,
    enter: 'P',
    useSplitMode: false,
    colorPickerDefaultTab: 'background',
    disablePlugins: ['paste', 'stat'],
    events: {},
    textIcons: false,
    uploader: {
        insertImageAsBase64URI: true,
        imageExtensions: ["jpg", "jpeg", "png", "gif"],
    },
    imageExtensions: ["jpg", "jpeg", "png", "gif"],
    placeholder: 'Type here',
    showXPathInStatusbar: false,
    imageProcessor: {
        replaceDataURIToBlobIdInView: Boolean
    },
    imageDefaultWidth: 300,
    selectionCellStyle: 'border: 1px solid #1e88e5 !important;',
    selectionCellStyle: 'width: 100% !important',
    useExtraClassesOptions: true,
    table: {
        width: 100,
        data: {
            classList: {
                'table table-bordered': 'Bootstrap Bordered',
                'table table-striped': 'Bootstrap Striped',
                'table table-dark': 'Bootstrap Dark'
            }
        }
    }
};

export const medicalCouncilOptions = {
    "Allopathy": [
        "Andhra Pradesh Medical Council",
        "Arunachal Pradesh Medical Council",
        "Assam Council of Medical Registration",
        "Bihar Medical Council",
        "CHD Medical Council",
        "Chhattisgarh Medical Council",
        "Delhi Medical Council",
        "Goa Medical Council",
        "Gujarat Medical Council",
        "Haryana Medical Council",
        "Himanchal Pradesh Medical Council",
        "Jammu and Kashmir Medical Council",
        "Jharkhand Medical Council",
        "Karnataka Medical Council",
        "Kerala State Medical Councils",
        "Madhya Pradesh Medical Council(Bhopal Medical Council / Mahakaushal Medical Council)",
        "Maharashtra Medical Council",
        "Manipur Medical Council",
        "State Medical Council, Meghalaya",
        "Mizoram Medical Council",
        "Nagaland Medical Council",
        "Orissa Council of Medical Registration",
        "Punjab Medical Council",
        "Rajasthan Medical Council",
        "Sikkim Medical Council",
        "Tamil Nadu Medical Council",
        "Telangana State Medical Council",
        "Tripura State Medical Council",
        "Uttarakhand Medical Council",
        "Uttar Pradesh Medical Council",
        "West Bengal Medical Council",
        "National Medical Commission(erstwhile Medical Council of India)"
    ],
    "Dentistry": [
        "Andhra Pradesh State Dental Council",
        "Arunachal Pradesh State Dental Council",
        "Assam State Dental Council",
        "Bihar State Dental Council",
        "Dental Council of Chandigarh",
        "Chhattisgarh State Dental Council",
        "Delhi State Dental Council",
        "Goa State Dental Council",
        "Gujarat State Dental Council",
        "Haryana State Dental Council",
        "Himachal Pradesh State Dental Council",
        "Jammu and Kashmir State Dental Council",
        "Jharkhand State Dental",
        "Council Karnataka State Dental Council",
        "Kerala Dental Council",
        "Madhya Pradesh State Dental Council",
        "Maharashtra State Dental Council",
        "Manipur State Dentists Registration Tribunal",
        "Meghalaya State Dental council",
        "Mizoram State Dentist Registration Tribunal",
        "Nagaland State Dental Council",
        "Orissa State Dental Council",
        "State Dental Council, Puducherry",
        "Punjab State Dental Council",
        "Rajasthan State Dental Council",
        "Sikkim Dental Registration Tribunal",
        "Tamil Nadu State Dental Council",
        "Telangana State Dental Council",
        "Tripura State Dental Council",
        "Uttarakhand Dentists Registration Tribunals",
        "Uttar Pradesh State Dental Council",
        "West Bengal State Dental Council",
        "Dental Council of India",
    ],
    "Ayurveda": [
        "Andhra Pradesh Board for Indian Medicine and Homoeopathy",
        "Council of Indian Medicine, Arunachal Pradesh",
        "Assam State Council of Indian Medicine",
        "Bihar State Council of Ayurvedic and Unani Medicine",
        "Chhattisgarh Board of Ayurvedic and Unani System of Medicine and Naturopathy",
        "Delhi Bhartiya Chikitsa Parishad",
        "Goa Council of Ayurvedic and other Allied Indian Systems of Medicine",
        "Gujarat Board of Ayurvedic & Unani Systems of Medicine",
        "Council of Indian Medicine, Haryana",
        "Board of Ayurvedic and Unani Systems of Medicine, Himachal Pradesh",
        "Board of Ayurvedic & Unani System of Medicine, Jammu and Kashmir",
        "Jharkhand State Ayush Medical Council",
        "The Karnataka Ayurvedic & Unani Practitioners Board",
        "Kerala State Medical Councils",
        "Madhya Pradesh Board of Ayurvedic and Unani Systems of Medicine and Naturopathy",
        "Maharashtra Council of Indian Medicine",
        "Orissa State Council of Ayurvedic Medicine",
        "Board of Ayurvedic & Unani Systems of Medicine, Punjab",
        "Board of Indian Medicine, Rajasthan",
        "Tamil Nadu Board of Indian Medicine, Madras",
        "Government of Telangana Board of Indian Medicine",
        "Bhartiya Chikitsa Parishad, Uttarakhand",
        "Bhartiya Chikitsa Parishad, Uttar Pradesh",
        "Paschim Banga Ayurved Parishad",
        "National Commission for Indian System of Medicine (erstwhile Central Council of Indian Medicine)"
    ],
    "Homeopathy": [
        "Andhra Pradesh Board for Indian Medicine and Homoeopathy",
        "Arunachal Pradesh Homoeopathic Council",
        "Board of Homoeopathic System of Medicine, Assam",
        "State Board of Homoeopathic Medicine, Bihar",
        "Homoeopathic system of Medicine, Chandigarh",
        "State Council of Homoeopathy, Chhattisgarh",
        "Board of Homoeopathic System of Medicine, Delhi",
        "The Goa Indian System of Medicine and Homoeopathy Council",
        "Council of Homoeopathic System of Medicine, Gujarat",
        "Council of Homoeopathic System of Medicine, Haryana",
        "Homoeopathic Medical Council, Himachal Pradesh",
        "Jammu and Kashmir Board of Homoeopathic System of Medicine",
        "Jharkhand State AYUSH Parishad",
        "Karnataka Board of Homoeopathic System of Medicine",
        "Kerala State Medical Councils",
        "Madhya Pradesh State Council of Homoeopathy",
        "Maharashtra Council of Homoeopathy",
        "Board of Homoeopathic Medicine, Meghalaya",
        "Mizoram State Council of Homoeopathy",
        "Board of Homoeopathic System of Medicine, Nagaland",
        "Odisha State Board of Homoeopathic Medicine",
        "Council of Homoeopathic System of Medicine, Punjab",
        "Rajasthan Homoeopathic Medical Board",
        "Tamil Nadu Homoeopathy Medical Council",
        "Government of Telangana Board of Indian Medicine",
        "Council of Homoeopathic Medicine, Tripura",
        "Homoeopathic Medicine Board, Uttarakhand",
        "Homoeopathic Medicine Board, Uttar Pradesh",
        "Council of Homoeopathic Medicine, West Bengal",
        "Central Council of Homoeopathy",
        "National Commission for Homoeopathy"
    ],
}


export const doctorProfileStatus = [
    "Select",
    "Registration Completed",
    "Onboarded",
    "Profile Activated",
    "Profile Suspended",
    "Profile Terminated"
]

export const fee = [
    "Less than INR 99",
    "INR 100 to INR 299",
    "INR 300 to INR 499",
    "INR 500 to INR 799",
    "INR 800 to INR 999",
    "More than INR 1000",
];

export const experience = [
    "Less than 1 Years",
    "1 - 2 Years",
    "2 - 3 Years",
    "3 - 5 Years",
    "5+ Years",
];

export const gender = [
    "Male",
    "Female",
    "Others",
];

export const language = [
    "Tamil",
    "Telugu",
    "Hindi",
    "English",
    "Malayalam",
    "Kannada",
    "Bengali",
    "Marathi",
    "Punjabi",
    "Others",
];

export const service = [
    "Physiotherapist",
    "Nurse",
    "Mental Wellness Therapist",
    "Caretakers / Caregivers",
    "Speech Therapist",
    "Elderly Care Support",
    "Mother and Baby Care",
];

export const filterOptions = ["Gender", "Experience", "Location", "Language", "Service Category", "Lead Status", "BD"]

export const Inventory = [

    "Less than 3 Lacs",
    "5 - 20 Lacs",
    "20 - 50 Lacs",
    "50 Lacs - 1 Crore",
    "More than 1 Crore",

];

export const sizeofPharmacy = [

    "120 to 150 Square feet",
    "150 to 200 Square feet",
    "200 to 300 Square feet",
    "300 to 500 Square feet",
    "More than 500 Square feet",

];

export const businessType = [

    "Sole-Proprietorship",
    "Private Limited",
    "HUF",
    "Partnership",
    "Public Limited",
    "OPC",
    "Others",

];

export const pharmacyFilterOptions = ["Inventory", "City / Town / Location", "Sizeof Pharmacy", "Business Type", "Lead Status", "BD"]

export const instaDocFilterOptions = ["Lead Status", "BD"]

export const type = [
    {
        text: "Bank",
    },
    {
        text: "NBFC",
    },
    {
        text: "Brand",
    },
    {
        text: "Seller",
    },
    {
        text: "Influencer",
    },
    {
        text: "Company",
    },
    {
        text: "Individual",
    },
];

export const specialisation = [
    {
        text: "Andrology",
    },
    {
        text: "Ayurveda",
    },
    {
        text: "Allergist",
    },
    {
        text: "Bariatrics",
    },
    {
        text: "Cardiology",
    },
    {
        text: "Cosmetology",
    },
    {
        text: "Dentistry",
    },
];

export const preferredDay = [
    {
        text: "Today",
    },
    {
        text: "Tomorrow",
    },
    {
        text: "Day After",
    },
];

export const status = [
    "Active",
    "Inactive"
]

export const partneredDoctorsGender = [
    "Male",
    "Female",
]

export const mode = [
    "Online",
    "Both"
]

export const establishTypes = [
    "Hospital",
    "Clinic"
]

export const inclinicMode = [
    "Inclinc",
    "Both"
]

export const filterOnlineDoctorsOptions = [
    "Primary Specialist",
    "Status",
    "Gender",
    "Mode",
    "Fee",
    "Language"
]

export const filterInclinicDoctorsOptions = [
    "Primary Specialist",
    "Status",
    "Gender",
    "City",
    "Mode",
    "Fee",
    "Language"
]

export const filterClinicProfileOptions = [
    "Clinic Specialty",
    "Clinic City",
    "Establish Type"
]

export const average = [
    "Less than 5 Lakhs",
    "5 - 10 Lakhs",
    "10 Lakhs to 15 Lakhs",
    "15 Lakhs - 20 Lakhs ",
    "20 Lakhs - 30 Lakhs ",
    "30 Lakhs - 50 Lakhs",
    "Above 50 Lakhs"
]

export const yesOrNo = [
    "Yes",
    "No"
]