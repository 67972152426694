import axios from "axios";
import ReactPaginate from "react-paginate";
import React, { useState, useEffect } from "react";
import Home from "../../Home/Home";
import { useNavigate } from "react-router";
import filter1 from "../../../assets/svg/Health Article/filtericon.svg";
import sort1 from "../../../assets/svg/Health Article/sortbyicon.svg";
import { FaAngleDown, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import search from "../../../assets/images/searchIcon.png";
import { FaAngleRight } from "react-icons/fa";
import Moment from "moment";
import { AiOutlineForward, AiOutlineBackward } from "react-icons/ai";
import { type } from "../../../constants";

// actions icons
import contact from "../../../assets/images/HealthSy/contact.png";
import onboard from "../../../assets/images/HealthSy/onboard.png";
import notcontact from "../../../assets/images/HealthSy/notcontacted.png";
import newdelete from "../../../assets/images/HealthSy/delete.png";
import Deletepopup from "../../modal/Deletepopup";
import AssignedTo from "../../modal/AssignedTo";
import SucessModal from "../../modal/SucessModal";
import LeadStatus from "../../modal/LeadStatus";
import LeadStatusConfirm from "../../modal/LeadStatusConfirm";
import apiCall from "../../../api";

const DoctorRegistration = () => {
  const [isChecked, setIsChecked] = useState(null);

  const [records, setRecords] = useState([]);
  const [filtered_records, setFilteredRecords] = useState([]);
  const [pages, setPages] = useState(0);
  const [current_page, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState("newest");
  const [sortName, setSortName] = useState("Sort by");

  //filter portion
  const [filtershow, setFilterShow] = useState(false);
  const [sortshow, setSortShow] = useState(false);
  const [textcolor, setTextColor] = useState(0);
  const [typedata, setTypeData] = useState([]);
  const [submitfilter, setSubmitFilter] = useState(false);

  const [assignModal, setAssignModal] = useState({ id: "", status: false })
  const [leadStatusModal, setLeadStatusModal] = useState({ id: "", status: false })
  const [success, setSuccess] = useState(false);
  const [leadStatussuccess, setLeadStatusSuccess] = useState(false);
  const [leadStatusConfirmModal, setLeadStatusConfirmModal] = useState({ leadType: "", status: false, id: "" })
  const [pageOffset, setPageOffset] = useState(0);
  const [del, setDel] = useState(false);
  const [id, setId] = useState("");

  // const[type, setType]=useState([])

  // useEffect(()=>{
  //   getTypeData()
  // }, [])

  // const getTypeData=()=>{
  //   axios
  //   .post(`${process.env.REACT_APP_API_URL}types/list`)
  //   .then((res)=>{
  //     setType(res.data.rows)
  //   })
  // }

  useEffect(() => {
    list_api_call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, sort, skip]);

  useEffect(() => {
    filter_call();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typedata, submitfilter]);

  useEffect(() => {
    if (current_page > 1) {
      setSkip((current_page - 1) * limit);
    } else {
      setSkip(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_page]);

  const list_api_call = async () => {
    const result = await apiCall(`other-registration/list`, "POST",
      {
        // ...filter,
        skip: skip,
        limit: limit,
        // key: "retail_pharmacy",
        sort: sort,
      })
    setFilteredRecords([]);
    setRecords(result.data?.rows);
    // setRowCount(result.data.count);
    setPages(Math.ceil(result.data.count / limit));
  };

  const filter_call = async () => {
    const result = await apiCall(`other-registration/list`, "POST",
      {
        values: {
          type: typedata,
        },
        skip: skip,
        limit: limit,
        sort: sort,
      })
    setRecords(result.data.rows);
    setPages(Math.ceil(result.data.count / limit));
  };

  const filter_records = (filter) => {
    let rows = records.filter((x) => {
      // return JSON.stringify(x).indexOf(filter) > -1;
      return JSON.stringify(x).toLowerCase().indexOf(filter.toLowerCase()) > -1;
    });
    setFilteredRecords(rows);
  };

  const handleDoctor = () => {
    navigate("/doctor-registration");
  };
  const handleService = () => {
    navigate("/healthcare-registration");
  };
  const handleRetail = () => {
    navigate("/pharmacy-registration");
  };

  const handleBookDemo = () => {
    navigate("/doctor-demo");
  };
  const handleInstaDoc = () => {
    navigate("/insta-doc");
  };

  //Actions

  //Delete Functionality

  const handleDelete = (id) => {
    fetch(`${process.env.REACT_APP_API_URL}other-registration/delete/${id}`, {
      method: "DELETE",
    }).then((result) => {
      result.json().then((res) => {
        list_api_call();
        setDel(false);
      });
    });
  };

  //Contact Functionality
  const handleContact = (id) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}other-registration/update/${id}`,
        {
          contact: true,
          notContacted: false,
          onBoard: false,
        }
      )
      .then((result) => {
        list_api_call();
      });
  };

  //NotContact Functionality
  const handleNotContact = (id) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}other-registration/update/${id}`,
        {
          contact: false,
          notContacted: true,
          onBoard: false,
        }
      )
      .then((result) => {
        list_api_call();
      });
  };

  //On-Board Functionality
  const handleOnBoard = (id) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}other-registration/update/${id}`,
        {
          contact: false,
          notContacted: false,
          onBoard: true,
        }
      )
      .then((result) => {
        list_api_call();
      });
  };

  const successModalClose = () => {
    setSuccess(false)
    setLeadStatusSuccess(false)
    list_api_call()
  }

  const handlePageChange = (event) => {
    setSkip(event.selected * limit)
    setPageOffset(event.selected);
  };

  return (
    <>
      {/* <Home> */}
      <div className="healtharticle">
        <div className="healtharticle-container">
          <div className="top-container ps-3 ">
            <div className="row">
              <div className="healtharticle-title ms-0">
                <span>
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    to="/dashboard"
                  >
                    Dashboard
                  </Link>
                </span>
                <span className="bread-crumb">&#62;</span>
                <span>
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    to="/doctor-registration"
                  >
                    HealthSynergy
                  </Link>
                </span>
                <span className="bread-crumb1" style={{ paddingLeft: "0.4%" }}>
                  &#62;
                </span>
                <span style={{ paddingLeft: "0.4%" }}>Others</span>
              </div>
            </div>

            <div className="careers-tab  ">
              <div className="career-tab-career " onClick={handleDoctor}>
                Doctors
              </div>
              <div className="career-tab-jobpost " onClick={handleRetail}>
                Retail Pharmacy
              </div>
              <div className="career-post-department" onClick={handleService}>
                Home Healthcare Service Providers
              </div>
              <div className="career-post-department" onClick={handleInstaDoc}>
                InstaDoc
              </div>
              <div className="career-post-position  other pink-line">
                Others
              </div>
              <div
                className="career-post-position "
                style={{ color: "#65626C" }}
                onClick={handleBookDemo}
              >
                Book a Demo
              </div>
            </div>
            <div className="healtharticle-show mt-5 ms-2">
              <div>Show</div>
              <div>
                <select
                  value={limit}
                  onChange={(e) => setLimit(e.target.value)}
                  className="me-2"
                >
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                  <option>100</option>
                  <option value="0">All</option>
                </select>
              </div>
              <div>entries</div>
              <div>
                <div>
                  {/* <FaSearch /> */}
                  <img src={search} alt="" />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    onChange={(e) => filter_records(e.target.value)}
                  />
                </div>
              </div>
              <div className="sortandfilter-new">
                <div className="sortByPosition">
                  <div>
                    <div>
                      <div
                        onClick={() => {
                          setSortShow(!sortshow);
                        }}
                      >
                        {sortName}
                      </div>
                      {/* <select onChange={(e) => setSort(e.target.value)}>
                          <option>Sort by</option>
                          <option value="id-desc"><div style={{color:"red"}}>Oldest</div></option>
                          <option value="name-asc"><div>Newest</div></option>
                          <option value="id-asc"><div>Alphabetical ↑</div></option>
                          <option value="id-asc"><div>Alphabetical ↓</div><span><img src="" alt="" /></span></option>
                        </select> */}
                    </div>
                    <div>
                      <img src={sort1} alt="" />
                    </div>
                  </div>
                </div>
                <div className="sortByDepartment">
                  <div>
                    <div>
                      <div
                        onClick={() => {
                          setFilterShow(true);
                        }}
                      >
                        Filter
                      </div>
                      {/* <select
                        name=""
                        id=""
                        // onChange={handleSort}
                      >
                        <option value="">Filter</option>
                        <option value="Asc to Des">Asc to Des</option>
                        <option value="Des to Asc">Des to Asc</option>
                      </select> */}
                    </div>
                    <div>
                      <img src={filter1} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="healtharticle-table other-Reg-Table ">
            <table className="">
              <thead className="">
                <tr>
                  <td className="align-middle">S.No</td>
                  <td className="align-middle">Name</td>
                  <td className="align-middle">Mobile Number</td>
                  <td className="align-middle">Email ID</td>
                  <td className="align-middle">Type</td>
                  <td className="align-middle">City / Town Location</td>
                  {/* <td className="align-middle">Pincode</td> */}
                  <td className="align-middle">Message</td>
                  <td className="align-middle">Registration Date and Time</td>
                  <td className="align-middle">Assigned To</td>
                  <td className="align-middle">Lead Status</td>
                  <td className="align-middle">Actions</td>
                </tr>
              </thead>
              <tbody>
                {(filtered_records.length > 0 ? filtered_records : records).map(
                  (x, i) => {
                    if (x.show === undefined || x.show === true)
                      return (
                        <tr key={x._id}>
                          <td>{(current_page - 1) * limit + (i + 1)}</td>
                          <td>{x.name}</td>
                          <td>{x.mobile}</td>
                          <td>{x.email}</td>
                          <td>{x.type}</td>
                          <td>{x.location}</td>
                          {/* <td>-</td> */}
                          <td>{x.message}</td>
                          <td>
                            {Moment(x.createdAt).format("DD MMMM YYYY LT")}
                          </td>
                          <td>{x.assigned_to ? x.assigned_to : "-"}</td>
                          <td>{x.lead_status ? x.lead_status : "-"}</td>
                          <td>
                            <span
                              className={`contact ${x.contact === true ? "contact-highlight" : ""
                                }`}
                              onClick={() => {
                                handleContact(x._id);
                              }}
                            >
                              Contact
                            </span>
                            <span className="contact-logo">
                              <img src={contact} alt="" />
                            </span>
                            <span
                              className={`onboard ${x.onBoard === true ? "onboard-highlight" : ""
                                }`}
                              onClick={() => {
                                // if (
                                //   x.contact === true &&
                                //   x.notContacted === false
                                // ) {
                                //   handleOnBoard(x._id, x.onBoard);
                                // }
                                handleOnBoard(x._id);
                              }}
                            >
                              On-Board
                            </span>
                            <span className="onboard-logo">
                              <img src={onboard} alt="" />
                            </span>
                            <span
                              className={`notcontact ${x.notContacted === true
                                ? "notcontact-highlight"
                                : ""
                                }`}
                              onClick={() => {
                                handleNotContact(x._id);
                              }}
                            >
                              Not Contacted
                            </span>
                            <span className="notcontacted-logo">
                              <img src={notcontact} alt="" />
                            </span>
                            <span
                              className="newdelete"
                              onClick={() => {
                                setId(x._id);
                                setDel(true);
                              }}
                            >
                              Delete
                            </span>
                            <span className="newdelete-logo">
                              <img src={newdelete} alt="newdelete" />
                            </span>

                            {/* <span
                              className="doctor-edit"
                              onClick={() => {
                                navigate(`/web-booking-update/${el._id}`);
                              }}
                            >
                              Edit
                            </span>
                            <span className="onboard-logo">
                              <img src={onboard} alt="edit-logo" />
                            </span> */}

                            <span
                              className="pe-1 primaryColor"
                              onClick={() => setAssignModal({ id: x._id, status: true })}
                            >
                              Assigned To
                            </span>
                            <span className="pe-1">
                              <FaAngleDown className="drop-down-arrow" />
                            </span>

                            <span
                              className="pe-1 primaryColor"
                              onClick={() => setLeadStatusModal({ id: x._id, status: true })}
                            >
                              Lead Status
                            </span>
                            <span className="pe-1">
                              <FaAngleDown className="drop-down-arrow" />
                            </span>

                          </td>
                        </tr>
                      );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div>
        <div className="mb-5 col d-flex pagination justify-content-end admin-pagination">
          <button
            className="page-link"
            onClick={() => handlePageChange({ selected: 0 })}

            disabled={skip === 0}
          >
            <AiOutlineBackward size={20} />
          </button>
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName="pagination my-0"
            activeClassName="active"
            forcePage={pageOffset}
          />
          <button
            className="page-link"
            onClick={() => handlePageChange({ selected: pages - 1 })}

            disabled={(skip / limit) === (pages - 1)}
          >
            <AiOutlineForward size={20} />
          </button>
        </div>
      </div>
      {/* </Home> */}
      {del === true && <Deletepopup handleDelete={handleDelete} id={id} />}
      {/*---------Filter Portion--------*/}
      {filtershow ? (
        <div className="filter-container">
          <div className="filter-header">Filter</div>
          <div className="filter-header-hr"></div>
          <div className="filter-content-doctor">
            <div
              className="filter-content-card-doctor"
              onClick={() => {
                setTextColor(1);
              }}
            >
              <div
                style={{
                  color: textcolor === 1 ? "#CB1B5B" : "",
                  fontWeight: textcolor === 1 ? "bold" : "",
                }}
              >
                Type
              </div>
              <div>
                <FaAngleRight className="rightarrow" />
              </div>
            </div>
          </div>
          <div className="filter-foot-btn">
            <div>
              <button
                onClick={() => {
                  setTypeData([]);
                }}
              >
                Clear
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  setFilterShow(false);
                  setSubmitFilter(true);
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {/*---------Type Portion--------*/}
      {textcolor === 1 && filtershow === true ? (
        <div className="filter-doctor-fee">
          <div className="filter-doctor-fee-header">Type</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {type.map((el, i) => {
              return (
                <div className="filter-doctor-fee-box">
                  <div>{el.text}</div>
                  <div className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      className={isChecked ? "checked" : ""}
                      name={el.text}
                      id=""
                      onChange={(e) => {
                        setTypeData([...typedata, e.target.name]);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
      {/*--------Sort By Portion---------*/}
      {sortshow ? (
        <div className="sortby-Common">
          <div
            onClick={() => {
              setTextColor(11);
              setSortShow(false);
              setSort("oldest");
              setSortName("Oldest");
            }}
            style={{
              color: textcolor === 11 ? "#CB1B5B" : "",
              fontWeight: textcolor === 11 ? "bold" : "",
            }}
          >
            Oldest
          </div>
          <div
            onClick={() => {
              setTextColor(12);
              setSortShow(false);
              setSort("newest");
              setSortName("Newest");
            }}
            style={{
              color: textcolor === 12 ? "#CB1B5B" : "",
              fontWeight: textcolor === 12 ? "bold" : "",
            }}
          >
            Newest
          </div>
          <div
            onClick={() => {
              setTextColor(13);
              setSortShow(false);
              setSort("ascending");
              setSortName("Ascending");
            }}
            style={{
              color: textcolor === 13 ? "#CB1B5B" : "",
              fontWeight: textcolor === 13 ? "bold" : "",
            }}
          >
            Alphabetical ↑
          </div>
          <div
            onClick={() => {
              setTextColor(14);
              setSortShow(false);
              setSort("descending");
              setSortName("Descending");
            }}
            style={{
              color: textcolor === 14 ? "#CB1B5B" : "",
              fontWeight: textcolor === 14 ? "bold" : "",
            }}
          >
            Alphabetical ↓
          </div>
        </div>
      ) : null}
      {assignModal.status === true && (
        <AssignedTo
          id={assignModal?.id}
          setAssignModal={setAssignModal}
          setSuccess={setSuccess}
          pathName="other-registration"
        />
      )}

      {success && (
        <SucessModal
          name="BD"
          post="Assigned"
          onClick={() => successModalClose()}
        />
      )}

      {leadStatusModal.status === true && (
        <LeadStatus
          id={leadStatusModal?.id}
          setLeadStatusModal={setLeadStatusModal}
          setLeadStatusSuccess={setLeadStatusSuccess}
          setLeadStatusConfirmModal={setLeadStatusConfirmModal}
        />
      )}

      {leadStatusConfirmModal.status === true && (
        <LeadStatusConfirm
          id={leadStatusConfirmModal?.leadType}
          setLeadStatusConfirmModal={setLeadStatusConfirmModal}
          setLeadStatusSuccess={setLeadStatusSuccess}
          leadStatusConfirmModal={leadStatusConfirmModal}
          formType="other-registration"
        />
      )}

      {leadStatussuccess && (
        <SucessModal
          name="Lead Status"
          post="Updated"
          onClick={() => successModalClose()}
        />
      )}
    </>
  );
};

export default DoctorRegistration;
